import React from "react";

const Subscribe = () => {
  // const handleButtonClick = () => {
  //   window.open(
  //     "https://docs.google.com/forms/d/e/1FAIpQLSflwiqkZTMvQa0osHAQB5fHSe3p74F0-o3r6FJYSicjazG4Gg/viewform?usp=sf_link"
  //   );
  // };

  const redirectToWhatsAppGroup = () => {
    window.open(`https://chat.whatsapp.com/LP1UpAuvYm68KjlG9lN0V8`);
  };

  return (
    <section className="mailchimp-one">
      <div className="container wow fadeInUp" data-wow-duration="1500ms">
        <div className="inner-container">
          <div className="mailchimp-one-title">
            <h2>Subscribe to Excellium Job Portal</h2>
          </div>
          {/* <form action="#" className="mailchimp-one__form"> */}
          <div className="mailchimp-one__form">
            <span
              style={{ fontSize: "20px", opacity: "0.9", marginBottom: "10px" }}
            >
              Unlock job opportunities and exclusive updates – Join our WhatsApp
              community 🤝 now!
            </span>
            <button
              className="thm-btn mailchimp-one__btn"
              type="submit"
              onClick={redirectToWhatsAppGroup}
              style={{ marginTop: "25px" }}
            >
              <span>Join Community</span>
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
