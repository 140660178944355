import React from "react";
import { Link } from "gatsby";

const BlogCard = ({
  extraClassName,
  image,
  date,
  commentCount,
  title,
  text,
  link,
}) => {
  return (
    <div className={`blog-one__single ${extraClassName}`}>
      <div className="blog-one__image">
        <div style={{ width: "300px", height: "350px" }}>
          <img
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            src={image}
            alt=""
          />
        </div>
        <div className="blog-hover-box">
          <div className="box">
            <div className="icon-box">
              <Link to={link.path}>
                <i className="far fa-link"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-one__content" style={{ padding: "5px 5px 5px 5px" }}>
        {/* <div className="blog-one__meta">
          <a href="#">
            <i className="far fa-calendar-alt"></i> {date}
          </a>
          <a href="#">
            <i className="far fa-comments"></i> {commentCount} comments
          </a>
        </div> */}
        <h3 style={{ marginTop: "45px" }}>
          <Link to={link.path}>{title}</Link>
        </h3>
        <div className="blog-one__text" style={{ paddingTop: "0px" }}>
          <p>{text}</p>
        </div>
        <Link to={link.path} className="thm-btn blog-one__btn">
          <span>
            <i className="fab fa-linkedin" style={{ marginRight: "5px" }}></i>{" "}
            {link.label}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
