export const SERVICE_DATA = {
  sectionTitle: {
    title: "Excellium-IT",
    text: "Our Platform Key Features",
  },

  posts: [
    {
      extraClassName: "",
      icon: "piloz-writing",
      title: "Web Design & Development",
      text:
        "Certified technical training: hands-on, rigorous, comprehensive curriculum.",
    },

    {
      extraClassName: "bg-3",
      icon: "piloz-user",
      title: "Professional Support",
      text:
        "LinkedIn, CRM/VCS training, Scrum certification: expert services for professional growth.",
    },
    {
      extraClassName: "bg-4",
      icon: "piloz-gear",
      title: "Career Counselling",
      text:
        "Career counseling for ideal paths, advancing in chosen field—tailored services for growth..",
    },

    {
      extraClassName: "",
      icon: "piloz-writing",
      title: "Interview Preparation",
      text:
        "Expert guidance, CV crafting, and interview prep for your dream job success.",
    },
  ],
};

export const CALL_TO_ACTION_DATA = {
  sectionTitle: {
    text: "",
    title: "Best Practices \n Optimized Approach",
  },
  paragraph:
    "We have a project management and task tracking software that allows creating, assigning and tracking tasks, setting deadlines, and monitoring individual progress. It includes visual workflow builder to improve productivity.",
  posts: [
    {
      title: "Online & Feasible",
      extraClassName: " ",
      icon: "piloz-devices",
    },
    {
      title: "Course Progress",
      icon: "piloz-networking",
      extraClassName: "color-2",
    },
  ],
  button: {
    link: "",
    label: "Discover More",
    target: "_blank",
  },
};

import videoBG from "@images/resources/soon.png";

export const VIDEO_DATA = {
  title: "Watch Our Video",
  image: videoBG,
  videoID: "LMlCN6_vUvs",
};

import appFeature1 from "@images/resources/active_professional_community.jpg";

export const APP_FEATURE_DATA = {
  sectionTitle: {
    title: "Active Professional Community",
    text: "Our Professional Groups Key Features",
  },
  image: appFeature1,
  paragraph: "",
  options: [
    {
      label: "Technical Support",
    },
    {
      label: "Knowledge Sharing",
    },
    {
      label: "Job Opportunities Alerts",
    },
  ],
  button: {
    link: "https://www.linkedin.com/company/excellium-online/",
    label: "Discover More",
  },
};

export const PRICING_DATA = {
  sectionTitle: {
    title: "Our Pricing Plans",
    text: "Choose Our Pricing",
  },
  monthly: [
    {
      count: "01",
      amount: "$20.00",
      name: "Basic Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
    {
      count: "02",
      amount: "$30.00",
      name: "Basic Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
    {
      count: "03",
      amount: "$60.00",
      name: "Basic Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
  ],
  yearly: [
    {
      count: "01",
      amount: "$40.00",
      name: "Premium Pack",
      extraClassName: "color-1",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
    {
      count: "02",
      amount: "$80.00",
      name: "Premium Pack",
      extraClassName: "color-2",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
    {
      count: "03",
      amount: "$100.00",
      name: "Premium Pack",
      extraClassName: "color-3",
      options: [
        { label: "Extra features" },
        { label: "Lifetime free support" },
        { label: "Upgrate options" },
        { label: "Full access" },
      ],
      button: {
        label: "Choose Plan",
        link: "#",
      },
    },
  ],
};

import testimonial1 from "@images/testimonials/testimonials-1-img-1.png";
import testimonial2 from "@images/testimonials/testimonials-1-img-2.png";
import testimonialShape from "@images/testimonials/testimonials-1-shape-1.png";
import testimonialShape2 from "@images/testimonials/testimonials-1-shape-2.png";

import user1 from "@images/testimonials/ariza_khan.jpeg";
import user2 from "@images/testimonials/raza_ali.jpeg";
import user3 from "@images/testimonials/hamza_ijaz.jpeg";
import user4 from "@images/testimonials/maaz_iqbal.jpeg";
import user5 from "@images/testimonials/anees_fatima.jpeg";
import user6 from "@images/testimonials/suhaib_ahmad.jpeg";
import user7 from "@images/testimonials/user-solid.png";
import user8 from "@images/testimonials/farman_tariq.jpeg";

export const TESTIMONIALS_DATA = {
  sectionTitle: {
    text: "Our Testimonials",
    title: "What They Says",
  },
  posts: [
    {
      extraClassName: " ",
      shape: testimonialShape,
      image: user1,
      title: "Areeza Khan Niazi",
      designation: "Software Engineer - Systems Ltd",
      text:
        "Great learning experience, thank you. Most appreciated the quiet time and smart technical approach to guide and excel.",
    },
    {
      extraClassName: "color-2",
      image: user2,
      shape: testimonialShape2,
      title: "Raza Ali",
      designation: "Software Engineer - Pikes Soft",
      text:
        "I want to express my gratitude to M.Jahanzaib and the supportive community.The hands-on training was invaluable for my professional growth. Thank you for the noble cause, more power to you.",
    },
    {
      extraClassName: " ",
      image: user3,
      shape: testimonialShape,
      title: "Hamza Ijaz",
      designation: "CS Student - UMT",
      text:
        "The training exceeded my expectations. The expert guidance and support was invaluable.",
    },
    {
      extraClassName: "color-2",
      image: user4,
      shape: testimonialShape2,
      title: "Maaz Iqbal",
      designation: "Software Engineer - Z2A Tech",
      text:
        "A huge thank you for the impactful training. The real-world approach was exactly what I needed to succeed..",
    },
    {
      extraClassName: " ",
      image: user5,
      shape: testimonialShape,
      title: "Anees Fatima",
      designation: "Software Engineer - UAF",
      text:
        "I am so impressed with the quality of training I received. It has helped me to achieve my career goals.",
    },
    {
      extraClassName: "color-2",
      image: user6,
      shape: testimonialShape2,
      title: "Suhaib Ahmad",
      designation: "Software Engineer - IIFA Tech",
      text:
        "The institute's effort to bridge the gap between academic knowledge and practical skills is reflected in its well-structured program and community support. It ensures quality education and professional development.",
    },
    {
      extraClassName: " ",
      image: user7,
      shape: testimonialShape,
      title: "Noman Ahmad",
      designation: "Software Engineer - UOA Fsd",
      text:
        "Impressed by the efficient system, customized course design and professional community support for any ambiguity. Quality education ensured",
    },
    {
      extraClassName: "color-2",
      image: user8,
      shape: testimonialShape2,
      title: "Farman Tariq",
      designation: "Software Engineer - TEKX Solutions",
      text:
        "The career counseling and skill-sharpening training helped me to become industry-ready at a time when I needed it most. It was truly a miraculous opportunity for me.",
    },
  ],
};

import appshot1 from "@images/app-shots/github.png";
import appshot2 from "@images/app-shots/Firebase.png";
import appshot3 from "@images/app-shots/info.jpeg";
import appshot4 from "@images/app-shots/react_js.png";
import appshot5 from "@images/app-shots/vs_code.png";
import appshot6 from "@images/app-shots/sdlc.png";
import appshot7 from "@images/app-shots/stlc.png";
import appshot8 from "@images/app-shots/mern_stack.png";

export const APP_SHOT_DATA = {
  sectionTitle: {
    title: "Tools & Technologies",
    text: "Latest",
  },
  posts: [
    {
      image: appshot1,
    },
    {
      image: appshot2,
    },
    {
      image: appshot3,
    },
    {
      image: appshot4,
    },
    {
      image: appshot5,
    },
    {
      image: appshot6,
    },
    {
      image: appshot2,
    },
    {
      image: appshot3,
    },
    {
      image: appshot4,
    },
    {
      image: appshot5,
    },
    {
      image: appshot7,
    },
    {
      image: appshot2,
    },
    {
      image: appshot3,
    },
    {
      image: appshot8,
    },
    {
      image: appshot5,
    },
  ],
};

export const FAQ_DATA = {
  sectionTitle: {
    text: "Frequently Asked Questions",
    title: "Have Any Question?",
  },
  posts: [
    {
      status: true,
      title: "What is the fee for the training?",
      text:
        "We are committed to providing valuable skills and guidance to our community at no financial cost. We believe in working for the betterment of society through accessible and affordable skills based education.",
    },
    {
      status: true,
      title:
        "Is there a physical location or specific time schedule required for the course?",
      text:
        "No physical presence is required, the course is entirely online, allowing you to learn at your own pace and schedule from the comfort of your home.",
    },
    {
      status: true,
      title:
        "What is the required background or prerequisites for this course?",
      text:
        "A positive attitude and willingness to learn is crucial for success in the course. However, a degree level above matriculation or background in CS and facing difficulty in programming is ideal for this platform.",
    },
    {
      status: true,
      title:
        "Is there any certification offered after completion of the course?",
      text:
        "This course boasts an abundance of certifications from reputable platforms, and further enhances the value by providing immediate hands-on experience.",
    },
  ],
};

import blog1 from "@images/blog/muhammad_abdullah.jpg";
import blog2 from "@images/blog/muhammad_jahanzaib.jpg";
import blog3 from "@images/blog/user_final.png";
import blog4 from "@images/blog/qaisar_image.png";

export const BLOG_DATA = {
  sectionTitle: {
    text: "opportunities don't happen, you create them",
    title: "Our Team",
  },
  posts: [
    {
      image: blog1,
      date: "",
      commentCount: "",
      title: "Muhammad Abdullah ",
      text: "Software Engineer",
      link: {
        path: "https://www.linkedin.com/in/muhammadab98/",
        label: "Visit Profile",
      },
    },
    {
      image: blog2,
      date: "",
      commentCount: "",
      title: "Muhammad Jahanzaib",
      text: "Software Engineer",
      link: {
        path: "https://www.linkedin.com/in/muhammad-jahanzaib-b84a36201",
        label: "Visit Profile",
      },
    },
    {
      image: blog3,
      date: "",
      commentCount: "",
      title: "Muhammad Shahzaib",
      text: "Software Engineer",
      link: {
        path: "https://www.linkedin.com/in/muhammad-shahzaib-381b0520a/",
        label: "Visit Profile",
      },
    },

    {
      image: blog4,
      date: "",
      commentCount: "",
      title: "Syed Qaisar Tanvir",
      text: "Software Engineer",
      link: {
        path: "https://www.linkedin.com/in/syed-qaisar-tanvir-b2291b227/",
        label: "Visit Profile",
      },
    },
  ],
};
